/*
 * Globals
 */

/* Links */

a {
  color: #fff;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

a:hover,
a:focus {
  color: rgba(255, 255, 255, 0.5);
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: 0.05rem solid #fff;
}

/*
 * Base structure
 */

html,
body {
  height: 100%;
  /*background-color: #344;*/
  background-size: cover;
}
body {
  color: #fff;
  text-align: center;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
}

/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper {
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;

  /*  -webkit-box-shadow: inset 0 0 5rem rgba(0,0,0,.5);
              box-shadow: inset 0 0 5rem rgba(0,0,0,.5);*/
}
.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}
.cover-container {
  margin-right: auto;
  margin-left: auto;
  background: rgba(34, 34, 34, 0.85);
  /*  -webkit-filter: blur(10px);
      filter: blur(10px);*/
}

/* Padding for spacing */
.inner {
  padding: 2rem;
}

/*
 * Cover
 */

.cover {
  padding: 0 1.5rem;
}
.cover .btn-lg {
  padding: 0.75rem 1.25rem;
  font-weight: bold;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

h1,
h2,
h3,
h4 {
  color: white !important;
}

.ant-form-item-label > label,
.ant-form-item-label,
.ant-radio-wrapper {
  color: white;
  text-align: left;
}

/*
 * Affix and center
 */

@media (min-width: 40em) {
  /* Pull out the header and footer */
  .cover {
    padding-top: 50px;
    margin-bottom: 100px;
    margin-top: 75px;
  }
  .masthead {
    position: fixed;
    top: 0;
    background: rgba(34, 34, 34, 0.9);
    margin-bottom: 10px;
    height: 75px;
  }
  .mastfoot {
    /*position: fixed;*/
    bottom: 0;
    /*background: #344;*/
  }
  /* Start the vertical centering */
  .site-wrapper-inner {
    vertical-align: middle;
  }
  /* Handle the widths */
  .masthead,
  .mastfoot,
  .cover-container {
    width: 100%; /* Must be percentage or pixels for horizontal alignment */
  }
}

@media (min-width: 50em) {
  .masthead,
  .mastfoot,
  .cover-container {
    width: 50rem;
  }
}

@media (max-width: 767px) {
  .masthead {
    height: 130px;
  }

  .cover {
    padding-top: 50px;
    margin-top: 130px;
  }
}

@media (max-width: 639px) {
  .cover {
    padding-top: 18px;
    margin-top: 0px;
  }
}

.empty-input {
  height: 32px;
  margin-bottom: 24px;
}

@media (max-width: 575px) {
  .empty-input {
    height: 69px;
  }
}

.left {
  text-align: left;
}