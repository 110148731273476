.masthead {
  margin-bottom: 2rem;
  z-index: 1000;
}

.masthead-brand {
  margin-bottom: 0;
  margin-top: -10px !important;
  color: white !important;
}

.nav-masthead .nav-link {
  font-size: 16px;
  padding: 0.25rem 0;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
  display: inline-block;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}
